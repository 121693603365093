import { render, staticRenderFns } from "./ChatWidgetGroupListV2.vue?vue&type=template&id=5cbc6390&scoped=true&"
import script from "./ChatWidgetGroupListV2.vue?vue&type=script&lang=js&"
export * from "./ChatWidgetGroupListV2.vue?vue&type=script&lang=js&"
import style0 from "./ChatWidgetGroupListV2.vue?vue&type=style&index=0&id=5cbc6390&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cbc6390",
  null
  
)

export default component.exports